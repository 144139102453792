import MenuList from "@/components/MenuList";
import Spinner from "@/components/Spinner";
import TooltipPopover from "@/components/TooltipPopover";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useStores } from "@/hooks/useStores";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import ListsControls from "@/src/lists/components/ListControls";
import useUserElements from "@/src/lists/hooks/useUserElements";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import usePaywall from "@hooks/usePaywall";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";

type Recipe = {
  id: number;
  [key: string]: any;
};

const SaveRecipe = ({
  recipe,
  theme = "dark",
  size = "sm",
  side = "right" as const,
  onClick = () => null,
  shouldSaveToDefaultMenu = true,
  avoidCollisions = true,
}: {
  recipe: Recipe;
  theme?: string;
  size?: string;
  side?: "right" | "left";
  onClick?: () => void;
  shouldSaveToDefaultMenu?: boolean;
  avoidCollisions?: boolean;
}) => {
  // Make sure the id of the recipe is a number to avoid any comparison issues
  recipe = { ...recipe, id: Number(recipe.id) };

  const { menuStore } = useStores();
  const { setPaywall } = usePaywall();
  const { subscriptionPermission } = useSubscriptionPermission(recipe);
  const { canUseRecipeSaving } = subscriptionPermission;
  const { currentUser, isLoggedIn } = useCurrentUser();

  const [icon, setIcon] = useState(<PlusIcon className="h-5 w-5" />);
  const [isSaving, setIsSaving] = useState(false);

  const hasListsMigrated = currentUser?.listsMigrated;

  const { elementIds, addElement, isAdding, isLoadingElementIds } =
    useUserElements({
      shouldFetch: isLoggedIn && hasListsMigrated,
      fetchElementIdsOnly: true,
    });

  const isRecipeSavedInMenuStore = menuStore.isRecipeSaved(recipe);
  const isRecipeSaved = elementIds.includes(recipe.id);

  useEffect(() => {
    let isLoading = false;
    let savedState = false;

    if (hasListsMigrated) {
      isLoading = isLoadingElementIds || isAdding;
      savedState = isRecipeSaved;
    } else {
      isLoading = menuStore.isFetching;
      savedState = isRecipeSavedInMenuStore;
    }

    setIsSaving(isLoading);

    const iconComponent =
      !canUseRecipeSaving || (!isLoading && !savedState) ? (
        <PlusIcon className="h-5 w-5" />
      ) : isLoading ? (
        <Spinner size="md" />
      ) : (
        <CheckIcon className="h-5 w-5" />
      );

    setIcon(iconComponent);
  }, [
    canUseRecipeSaving,
    hasListsMigrated,
    isAdding,
    isLoadingElementIds,
    isRecipeSaved,
    isRecipeSavedInMenuStore,
    menuStore.isFetching,
    recipe.id,
  ]);

  const handleClick = useCallback(
    ({ setIsPopoverOpen, setIsPopoverActive }) => {
      if (!canUseRecipeSaving) {
        setPaywall({
          isOpen: true,
          isClosable: true,
          variant: "saveAndCreateCollection",
          title: recipe.title,
        });
        onClick();
        return;
      }

      if (hasListsMigrated) {
        if (!isRecipeSaved) {
          addElement(recipe.id);
        }
      } else {
        menuStore.setActiveSaveRecipe(recipe);
        if (shouldSaveToDefaultMenu) addToDefaultMenu();
      }

      setIsPopoverOpen(true);
      setIsPopoverActive(true);
      onClick();
    },
    [
      canUseRecipeSaving,
      recipe.title,
      onClick,
      hasListsMigrated,
      elementIds,
      shouldSaveToDefaultMenu,
    ]
  );

  const addToDefaultMenu = useCallback(() => {
    if (menuStore.isFetching) return;
    if (menuStore.defaultMenu) {
      if (!menuStore.defaultMenu.isInMenu(recipe)) {
        menuStore.defaultMenu.addRecipe(recipe);
      }
    } else {
      menuStore.createDefaultMenu(recipe);
    }
  }, [menuStore, recipe]);

  return (
    <div className="SaveRecipe">
      <TooltipPopover
        disabled={isSaving}
        onClick={handleClick}
        side={side}
        avoidCollisions={avoidCollisions}
        hideWhenDetached={false}
      >
        <TooltipPopover.TooltipTrigger
          className={clsx("", {
            "icon-button-sm": size === "sm",
            "icon-button-lg": size === "lg",
            "icon-button-light": theme === "light",
            "icon-button-dark": theme === "dark",
          })}
          label="Save Recipe & Create Collection"
        >
          {icon}
        </TooltipPopover.TooltipTrigger>
        <TooltipPopover.TooltipContent>
          Save Recipe & Create Collection
        </TooltipPopover.TooltipContent>
        <TooltipPopover.PopoverContent>
          <div className={clsx("", { hidden: isSaving })}>
            {hasListsMigrated ? (
              <ListsControls entry={recipe} />
            ) : (
              <MenuList recipe={recipe} />
            )}
          </div>
        </TooltipPopover.PopoverContent>
      </TooltipPopover>
    </div>
  );
};

export default observer(SaveRecipe);
